.transition-item-enter {
  opacity: 0;
  display: none;
}
.transition-item-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.transition-item-exit {
  opacity: 1;
}
.transition-item-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}
