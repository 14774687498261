@font-face {
  font-family: "EuclidCircularB-Bold";
  src: url("/fonts/EuclidCircularB-Bold-WebS.woff");
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  font-display: block;
}

@font-face {
  font-family: "EuclidCircularB-Medium";
  src: url("/fonts/EuclidCircularB-Medium-WebS.woff");
  font-weight: 400;
  font-variant: normal;
  font-display: block;
}

@font-face {
  font-family: "EuclidCircularB-Regular";
  src: url("/fonts/EuclidCircularB-Regular-WebS.woff");
  font-weight: 600;
  font-variant: normal;
  font-display: block;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "EuclidCircularB-Regular", Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: white;
}

body.menu-open {
  overflow: hidden;
}

/* Turn off smooth scroll for those who prefer reduced motion */
@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  margin: 0;
}

.rich-text {
  font-size: 18px;
  line-height: 1.5;
}

.rich-text > *:not(:first-child) {
  margin-top: 20px;
}

.rich-text > *:first-child {
  margin-top: -7px;
}

.rich-text > *:empty {
  display: none;
}

.rich-text ul {
  margin-left: 20px;
}

.rich-text li {
  list-style: disc !important;
  margin-top: 10px !important;
}

.rich-text strong {
  font-family: "EuclidCircularB-Bold", Helvetica Neue, sans-serif;
  color: #c7ff00;
  font-size: 20px;
}

@media (max-width: 959px) {
  .rich-text {
    font-size: 16px;
  }
  .rich-text strong {
    font-size: 18px;
  }
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

button {
  background: none;
  border: none;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

/* Animations */

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.slide-up,
.fade-in {
  opacity: 0;
}

.menu .slide-up {
  opacity: 1;
}

.slide-up--active {
  animation-name: slideUp;
  animation-duration: 600ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in--active {
  animation-name: fadeIn;
  animation-duration: 800ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
}

.marquee-animation {
  animation-iteration-count: infinite;
  animation-name: marquee;
  animation-timing-function: linear;
}

@keyframes marquee {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}
