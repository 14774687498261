.menu-background {
  background-color: #020a66;
  width: 350vw;
  height: 350vw;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) scale(1e-8);
  transition: transform var(--transitionDelay) ease-in-out;
  will-change: transform;
}

.menu-background--active {
  transform: translate3d(-50%, -50%, 0);
}

@media screen and (orientation: portrait) {
  .menu-background {
    width: 350vh;
    height: 350vh;
  }
}
