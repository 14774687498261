.stickyHeading-heading-transitionUp {
  transform: translateY(-50px);
  transition: transform 350ms ease-in-out;
  padding-top: 50px;
}

.stickyHeading-heading-transitionDown {
  transform: translateY(50px);
  transition: transform 350ms ease-in-out;
}

.TwoColTwoStickyHeadings {
  grid-template-areas:
    "heading1 item1"
    "heading1 item2"
    "heading2 item3";
}
