.job-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 23px;
  grid-row-gap: 30px;
}

@media (min-width: 640px) {
  .job-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1280px) {
  .job-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 45px;
    grid-row-gap: 40px;
  }
}
