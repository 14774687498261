.article-list {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 23px;
  grid-row-gap: 30px;
}

@media (min-width: 640px) {
  .article-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 960px) {
  .article-list {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 3%;
    grid-row-gap: 110px;
  }
}
