.SplitTextHeading {
  font-family: "EuclidCircularB-Bold";
  font-size: 30px;
  white-space: pre-wrap;
  line-height: 1.16;
  margin-top: 30px;
}

@media (min-width: 640px) {
  .SplitTextHeading {
    font-size: 55px;
  }
}

@media (min-width: 1280px) {
  .SplitTextHeading {
    font-size: 75px;
  }
}

.SplitText {
  display: inline-block;
}

.SplitWordWrapper {
  display: inline-block;
  overflow: hidden;
  padding-bottom: 7px;
  margin-top: -30px;
}

.SplitWord {
  display: inline-block;
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.SplitWord.entered {
  animation-name: slideUp;
  animation-duration: 600ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
}

.SplitGradient {
  background: linear-gradient(60deg, #3affc6 24%, #c7ff00);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.SplitGradient--first {
  padding-bottom: 7px;
}

@media (max-width: 639px) {
  .SplitGradient--first {
    max-width: 250px;
  }
}

.line-height-adjust {
  display: inline-block;
  margin-top: -7px;
}
