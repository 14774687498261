@keyframes drawLine {
  0% {
    transform: scale3d(0, 1, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}

.gradient-line {
  background: linear-gradient(90deg, #3affc6 0%, #c7ff00 100%);
  height: 2px;
  width: 100%;
  transform-origin: left;
  opacity: 0;
}

.gradient-line--animate {
  opacity: 1;
  animation-name: drawLine;
  animation-duration: 600ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: both;
}
